.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;

  &__w1400 {
    max-width: 1400px;
    padding: 0;
  }
}

.modal {
  &__menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2000000001;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    overflow: scroll;

    .header__logo {
      margin-right: 0;
      margin-bottom: 50px;
    }

    .btn {
      margin-bottom: 50px;
    }
  }

  &__content {
    border-radius: 5px;
    padding: 30px;
    background-color: #fff;
    position: relative;
    min-height: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto;
    max-width: 320px;

    &.wide {
      max-width: 820px;

      @media screen and (max-width: 860px) {
        margin: 20px;
      }
    }

    @media screen and (max-width: 500px) and (max-height: 750px) {
      border-radius: 0;
      margin: 0 !important;
      max-width: 100% !important;
      min-height: 100%;
    }
  }

  &__close {
    position: relative;
    width: 25px;
    height: 25px;
    display: flex;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 5px;

    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #ededed;
      top: 50%;
    }

    &:after {
      transform: translateY(-50%) rotate(45deg);
    }

    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &__phone {
    font-size: 30px;
    color: #000;
    text-decoration: none;
    margin-bottom: 90px;
  }

  &__list {
    margin-bottom: 90px;
  }

  &__item {
    padding: 15px;
    width: 100%;
    text-align: center;

    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    & > button,
    & > a {
      font-size: 18px;
      color: #000;
      text-decoration: none;

      &.active {
        color: rgb(16, 114, 117);
        font-weight: bold;
      }
    }

    & > button {
      text-decoration: none;
      padding: 0;
      border: 0;
      background: transparent;
      font-family: "Montserrat", sans-serif;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    &_item {
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.333;
  text-align: left;
  letter-spacing: -2px;

  @media screen and (max-width: 501px) {
    word-break: break-word;
  }

  &_big {
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.desc {
  font-size: 24px;
  margin-bottom: 35px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -1px;

  @media screen and (max-width: 501px) {
    font-size: 18px;
  }
}

.block_register {
  max-width: 650px;
  margin-left: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1001px) {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
  }

  @media screen and (max-width: 501px) {
    padding-bottom: 310px;
  }

  &__title {
    font-weight: bold;
    font-size: 68px;
    line-height: 1.111;
    text-align: left;
    margin-bottom: 30px;

    @media screen and (max-width: 1001px) {
      text-align: center;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 769px) {
      font-size: 48px;
      line-height: 1;
    }
  }

  &__desc {
    font-size: 24px;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 10px;

    @media screen and (max-width: 1001px) {
      text-align: center;
      margin-bottom: 45px;
    }

    @media screen and (max-width: 769px) {
      font-size: 20px;
      line-height: 1;
    }

    @media screen and (max-width: 481px) {
      font-size: 16px;
    }
  }
}

.top_background {
  background-image: url("assets/gr_image.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding-bottom: 50px;

  @media screen and (max-width: 1400px) {
    background-image: url("assets/top_background.png");
    padding-bottom: 0;
  }

  @media screen and (max-width: 501px) {
    background-image: url("assets/top_background_mobile.png");
    background-position: top center;
    background-size: auto;
  }

  &__car {
    background-image: url("assets/top_background_car.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media screen and (max-width: 1400px) {
      background-image: url("assets/top_background_car.jpg");
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/car_loan_background_320.png");
      background-position: bottom center;
      background-color: #fef8f5;
    }
  }

  &__payday {
    position: relative;
    background-color: #f5f3f6;

    .container {
      position: relative;
      z-index: 1;
    }
  }

  &__family_loan {
    position: relative;

    & > .container {
      position: relative;
      z-index: 1;
    }

    background-color: #f5f3f6;
  }

  &__business {
    background-image: url("assets/business_loan_background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #f9efde;

    @media screen and (max-width: 1400px) {
      background-color: #fbf2ec;
      background-image: url("assets/business_loan_background.png");
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/business_loan_background_320.png");
      background-size: contain;
    }
  }

  &__insurance_services {
    background-image: url("assets/insurance_services_top_background_4k.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #ebe4dd;

    @media screen and (max-width: 1400px) {
      background-color: #f5f0ec;
      background-image: url("assets/insurance_services_top_background.png");
      background-size: auto;
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/insurance_services_top_background_320.png");
    }
  }

  &__medical_services {
    background-image: url("assets/medical_services_top_background_4k.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #ebe4dd;

    @media screen and (max-width: 1400px) {
      background-color: #f5f0ec;
      background-image: url("assets/medical_services_top_background.png");
    }
  }

  &__study_loan {
    & > .container {
      position: relative;
      z-index: 1;
    }

    position: relative;
    background-color: #f5f3f6;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgb(245, 243, 246, 0.5);
      z-index: 1;
    }
  }

  &__personal_loan {
    background-image: url("assets/personal_loan_top_background_4k.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #ebe4dd;

    @media screen and (max-width: 1400px) {
      background-color: #f5f0ec;
      background-image: url("assets/personal_loan_top_background.png");
    }
  }

  &__refinancing {
    background-image: url("assets/refinancing_loan_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: #f9efde;

    @media screen and (max-width: 1400px) {
      background-color: #fbf2ec;
      //background-image: url("assets/business_loan_background.png");
    }

    @media screen and (max-width: 501px) {
      //background-image: url("assets/business_loan_background_320.png");
      //background-size: contain;
    }
  }
}

/* our_products */

.our_products {
  margin-top: 375px;

  .swiper {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 345px;
  }

  @media screen and (max-width: 769px) {
    .swiper {
      display: block;
    }
  }

  @media screen and (max-width: 501px) {
    margin-top: 10px;
  }

  &__title {
    font-size: 36px;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;

    @media screen and (max-width: 769px) {
      text-align: center;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    @media screen and (max-width: 769px) {
      display: none;
    }
  }

  &__item {
    background-color: #e6e6e6;
    padding: 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      min-height: 32px;
    }

    &_desc {
      font-size: 12px;
      margin-bottom: auto;
    }
  }
}

/* get_loan */

.get_loan {
  background-image: url("assets/gr_image2.webp");
  background-repeat: no-repeat;
  background-position: top center;
  padding: 80px 0;

  .swiper {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    background-image: url("assets/credit_background.jpg");
    background-position: 50% 50%;
  }

  @media screen and (max-width: 769px) {
    margin-top: 50px;

    .swiper {
      display: block;
    }
  }

  @media screen and (max-width: 501px) {
    background-image: url("assets/credit_background_mobile.jpg");
    background-position: top center;
    padding-top: 130px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 115px;

    @media screen and (max-width: 769px) {
      margin-bottom: 60px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (max-width: 769px) {
      font-size: 42px;
    }

    @media screen and (max-width: 501px) {
      font-size: 36px;
      margin-bottom: 25px;
    }
  }

  &__desc {
    max-width: 795px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    @media screen and (max-width: 501px) {
      font-size: 16px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 125px;
    grid-row-gap: 125px;

    @media screen and (max-width: 876px) {
      grid-column-gap: 50px;
      grid-row-gap: 50px;
    }

    @media screen and (max-width: 769px) {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_img {
      height: 100px;

      & > img {
        max-width: 100%;
      }
    }

    & > img {
      max-width: 100%;
    }

    &_text {
      margin-top: 25px;
      font-size: 24px;
      text-align: center;
      line-height: 1.25;
      font-weight: 700;
    }

    &_desc {
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
      line-height: 1.25;
    }
  }
}

/* car_loan_programs */

.car_loan_programs {
  margin-top: 105px;

  @media screen and (max-width: 1201px) {
    margin-top: 55px;
  }

  @media screen and (max-width: 501px) {
    margin-top: 25px;

    .title,
    .desc {
      text-align: center;
    }
  }

  @media screen and (max-width: 405px) {
    margin-top: 25px;

    .btn__group {
      flex-direction: column;
      justify-content: center;

      & > .btn {
        margin-left: 0;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1201px) {
      flex-direction: column-reverse;
    }
  }

  &__block {
    &_img {
      margin-right: 65px;

      img {
        max-width: 100%;
      }
    }
  }

  &__info {
    margin-right: 200px;
    max-width: 420px;

    @media screen and (max-width: 1201px) {
      max-width: 685px;
      margin-right: 0;
      margin-bottom: 30px;
      padding: 0 40px;
    }
  }

  .btn__pink {
    padding: 10px 40px;
  }
}

/*  pay_les */

.pay_les {
  margin-top: 80px;

  .btn__pink {
    padding: 10px 40px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 501px) {
    .title {
      text-align: center;
    }

    .btn__group {
      justify-content: center;
    }

    & ~ .background_img {
      position: relative;
      z-index: -1;
      top: -80px;
    }
  }
}

.background_img {
  img {
    max-width: 100%;
  }

  &__table {
    display: block;

    @media screen and (max-width: 501px) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @media screen and (max-width: 501px) {
      display: block;
    }
  }
}

/* loan_repayment */

.loan_repayment {
  margin-top: 105px;

  @media screen and (max-width: 501px) {
    margin-top: 0;
    background-image: url("assets/loan_repayment_background_mobile.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .title {
    text-align: center;
  }

  &__list {
    margin-top: 65px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 155px;

    @media screen and (max-width: 1001px) {
      grid-column-gap: 90px;
    }

    @media screen and (max-width: 779px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 90px;
      grid-row-gap: 90px;
      margin-top: 35px;
    }

    @media screen and (max-width: 501px) {
      padding-bottom: 325px;
    }

    @media screen and (max-width: 379px) {
      grid-column-gap: 40px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    @media screen and (max-width: 501px) {
      margin-top: 25px;
    }
  }
}

/* zmi */

.zmi {
  .swiper {
    display: none;
  }

  @media screen and (max-width: 1001px) {
    .swiper {
      margin-top: 50px;
      padding-top: 80px;
      display: block;
    }
  }

  &__list {
    margin-top: 130px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5px;

    @media screen and (max-width: 1001px) {
      display: none;
    }
  }

  &__item {
    background-color: #e6e6e6;
    position: relative;
    padding: 75px 20px 20px 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
    min-height: 50px;
    line-height: 1.5;
    text-align: left;
  }

  &__desc {
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__block_img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    text-align: center;
  }
}

.loan_term_background {
  margin-top: 95px;
}

/* about_us */
.about_us {
  background-color: #f5f3f6;

  .title {
    @media screen and (max-width: 769px) {
      text-align: center;
    }
  }

  &__wrapper {
    margin-top: 60px;
    padding-bottom: 60px;
  }

  &__block {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
  }

  &__item {
    padding: 20px;
    display: flex;
    background-color: #c5b3ac;
    font-size: 16px;
    color: rgb(30, 30, 30);
    text-align: left;

    @media screen and (max-width: 769px) {
      flex-direction: column;

      &.flex_direction_row {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > .about_us__right {
          margin-top: 10px;
        }

        & > .about_us__left {
          width: 215px;
        }
      }

      .btn {
        padding: 10px 25px;
      }
    }

    &:nth-child(odd) {
      background-color: #fff;
    }
  }

  &__left {
    width: 380px;
    font-weight: bold;
    margin-right: 45px;

    @media screen and (max-width: 769px) {
      width: auto;
      margin-right: 10px;
    }
  }

  &__right {
    display: block;
    word-break: break-all;
    width: 100%;
    line-height: 24px;

    @media screen and (max-width: 769px) {
      width: auto;
      margin-top: 25px;
    }
  }

  &__color {
    &_white {
      background-color: #fff !important;
    }

    &_bluish_gray {
      background-color: #c5b3ac !important;
    }
  }

  &__list {
    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media screen and (max-width: 769px) {
        justify-content: space-between;
        flex-wrap: wrap;

        & > div:first-child {
          width: 215px;
          margin-right: 10px;
        }

        & > div:last-child {
          margin-top: 10px;
          width: auto;
        }
      }

      & > div {
        width: 50%;
      }
    }
  }

  .footer__top-figure {
    z-index: 0;
  }
}

/* blog */

.blog {
  .top_background {
    background-color: #f5f3f6;
    background-image: none;
    //background-position: bottom center;
    //background-size: contain;

    //@media screen and (max-width: 1401px) {
    //  background-image: url("assets/top_background_blog.jpg");
    //}
  }

  &__wrapper {
    margin-top: 60px;

    @media screen and (max-width: 769px) {
      & > .title {
        text-align: center;
      }
    }
  }

  &__wrapper-grid {
    @media screen and (max-width: 1400px) {
      padding-bottom: 50px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 50px;
    margin-top: 60px;

    @media screen and (max-width: 1251px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 830px) {
      grid-template-columns: 1fr;
      margin: 0 20px;
    }
  }

  &__block {
    display: grid;
    grid-template-rows: 1fr 50px;
    gap: 12px;

    & > .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 148px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    //@media screen and (max-width: 650px) {
    //  display: none;
    //
    //  &:nth-child(1),
    //  &:nth-child(2),
    //  &:nth-child(3) {
    //    display: flex;
    //  }
    //}

    .btn {
      padding: 15px 35px;
    }
  }

  &__img {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;

    & > img,
    & > picture > img {
      max-width: 100%;
      max-height: 277px;
      min-height: 260px;
      object-fit: cover;

      @media screen and (max-width: 830px) {
        min-height: auto;
      }
    }
  }

  &__video {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;

    & > video {
      max-height: 277px;
      width: 390px;
      height: 281px;
      border-radius: 10px;
      overflow: hidden;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }

  &__desc {
    margin-bottom: 15px;
    font-size: 18px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0;

    .btn {
      padding: 15px 60px;
    }
  }
}

.loan_calc_apply_btn {
  font-size: 15px;
  height: 37.65px;
  padding-top: 0;
  padding-bottom: 0;
}

/* Car Loan */

.car_loan {
  @media screen and (max-width: 1221px) {
    .car_loan__calculate {
      grid-template-columns: 1fr;
      padding: 30px;
    }

    .loan_calculate__slider {
      margin-bottom: 55px;
      margin-top: 0;
    }
  }

  .footer {
    background-color: #fcf0eb;

    & > .footer_background {
      background-color: #fcf0eb;
      background-image: url("assets/footer_background.png");
    }

    .footer__top {
      @media screen and (max-width: 601px) {
        padding-top: 400px;
      }
    }
  }

  &__wrapper {
    margin-top: 40px;

    @media screen and (max-width: 501px) {
      padding-bottom: 240px;
    }
  }

  &__calculate {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px 30px;
    display: grid;
    grid-template-columns: 1fr minmax(200px, 385px);

    @media screen and (max-width: 701px) {
      grid-template-columns: 1fr;
      padding: 30px;
    }

    &_subtitle {
      font-size: 24px;

      @media screen and (max-width: 1221px) {
        display: none;
      }
    }

    &_left {
      margin-right: 20px;

      @media screen and (max-width: 1221px) {
        margin-bottom: 35px;
      }

      @media screen and (max-width: 701px) {
        margin-right: 0;
      }

      .title {
        margin-top: 40px;

        @media screen and (max-width: 1221px) {
          display: none;
        }
      }
    }

    &_right {
      background-color: #fff;

      @media screen and (max-width: 701px) {
        margin-top: 20px;
      }
    }

    &_info {
      margin-top: 65px;
      font-size: 14px;
      line-height: 24px;

      &__link {
        display: block;
        margin-bottom: 8px;
        cursor: pointer;
        font-size: 16px;
        color: #000;
      }

      &_table {
        @media screen and (max-width: 1221px) {
          display: none;
        }
      }

      &_mobile {
        display: none;

        @media screen and (max-width: 1221px) {
          display: block;
        }
      }
    }
  }

  &__left {
    display: none;

    @media screen and (max-width: 1221px) {
      display: block;
    }
  }

  &__benefit {
    background-image: url("assets/car_background_benefit.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    @media screen and (max-width: 501px) {
      background-image: url("assets/car_background_benefit_320.png");
      margin-top: 0;
      padding-top: 60px;
    }

    .get_loan__list {
      @media screen and (max-width: 1026px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }

      @media screen and (max-width: 769px) {
        display: grid;
      }

      @media screen and (max-width: 401px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .get_loan__item {
        justify-content: flex-start;
      }

      .get_loan__item:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;

        @media screen and (max-width: 1026px) {
          grid-column-start: 2;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 2;
        }

        @media screen and (max-width: 401px) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 4;
          grid-row-end: 4;
        }
      }

      .get_loan__item:nth-child(5) {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;

        @media screen and (max-width: 1026px) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 3;
        }

        @media screen and (max-width: 401px) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 5;
          grid-row-end: 5;
        }
      }
    }
  }

  &_who_can {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 501px) {
      padding-top: 35px;
    }

    &__title {
      font-size: 33px;
      line-height: 1.333;
      font-weight: bold;
      margin-bottom: 60px;

      @media screen and (max-width: 769px) {
        text-align: center;
        margin-bottom: 35px;
      }
    }

    &__desc {
      font-size: 18px;
      line-height: 30px;

      &:not(:first-child) {
        margin-top: 40px;

        @media screen and (max-width: 769px) {
          margin-top: 35px;
        }
      }
    }

    &__block {
      @media screen and (max-width: 650px) {
        height: 305px;
        overflow: hidden;
      }

      &.active {
        @media screen and (max-width: 650px) {
          height: auto;
        }
      }

      &_btn {
        display: none;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        @media screen and (max-width: 650px) {
          display: flex;
        }
      }
    }
  }

  &_how_get_loan {
    padding: 215px 0 100px 0;
    background-image: url("assets/car_loan_third_background.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    @media screen and (max-width: 501px) {
      background-image: url("assets/car_loan_third_background_320.png");
      padding: 95px 0 35px 0;
    }

    &__swiper {
      .get_loan__item_text {
        white-space: normal;
      }

      &.swiper {
        display: none;

        @media screen and (max-width: 769px) {
          display: block;
          margin-bottom: 70px;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      text-align: center;
      margin-bottom: 155px;

      @media screen and (max-width: 769px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 501px) {
        margin-bottom: 50px;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 75px;
      margin-bottom: 75px;

      @media screen and (max-width: 1031px) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
      }

      @media screen and (max-width: 769px) {
        display: none;
      }
    }

    &__item {
      white-space: nowrap;

      &.get_loan__item:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;

        @media screen and (max-width: 1031px) {
          grid-column-start: auto;
          grid-column-end: auto;
          grid-row-start: auto;
          grid-row-end: auto;
        }
      }

      &.get_loan__item:nth-child(6) {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;

        @media screen and (max-width: 1031px) {
          grid-column-start: auto;
          grid-column-end: auto;
          grid-row-start: auto;
          grid-row-end: auto;
        }
      }
    }

    &__block_btn {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (max-width: 650px) {
          padding-left: 90px;
          padding-right: 90px;
        }
      }
    }
  }
}

/* Loan calculate */

.loan_calculate {
  .MuiSlider-thumb {
    color: rgb(16, 114, 117);

    &:active,
    &:hover {
      box-shadow: 0 0 0 8px rgba(16, 114, 117, 0.16);
    }
  }

  .MuiSlider-rail {
    color: rgb(16, 114, 117);
    opacity: 1;
  }

  .MuiSlider-track {
    color: #f07c93;
  }

  &__left {
    &_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  &__right {
    background-color: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 501px) {
      padding: 0;
    }

    &__block {
      margin-bottom: 30px;
    }

    &__title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #000;
    }

    &__number {
      font-size: 14px;
      font-weight: bold;
      color: #0c6a6c;
    }

    &__desc {
      font-size: 14px;
    }
  }

  &__slider {
    margin-top: 55px;

    @media screen and (max-width: 701px) {
      &:first-child {
        margin-top: 0;
      }
    }

    &_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &_desc {
      font-size: 11px;
    }

    &_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__promocode {
    margin-top: 40px;

    &_block {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__block_select {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 20px;

    @media screen and (max-width: 821px) {
      grid-template-columns: 1fr;
    }

    .MuiFormControl-root {
      background-color: #fff;
    }

    &_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

/* payday loan */

.payday_loan {
  .footer__top-figure {
    background-color: #f5f3f6;
  }

  &__wrapper {
    padding: 75px 0 90px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
      padding: 25px 0 75px 0;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      @media screen and (max-width: 701px) {
        grid-template-rows: 1fr;
      }

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;

        @media screen and (max-width: 701px) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 3;
          grid-row-end: 3;
        }
      }
    }
  }

  &__left {
    margin-right: 25px;
  }

  &_from_us {
    padding: 150px 0 75px 0;
    background-color: #ffffff;
    background-image: url("assets/get_a_loan_from_us_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;

    @media screen and (max-width: 1121px) {
      margin-top: 0;
      padding: 65px 0 100px 0;
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/get_a_loan_from_us_background_320.png");
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    @media screen and (max-width: 769px) {
      .get_loan__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
      }

      .get_loan__item:nth-child(4) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }

      .get_loan__item:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }
    }

    @media screen and (max-width: 389px) {
      .get_loan__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 20px;
      }

      .get_loan__item:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 4;
        grid-row-end: 4;
      }

      .get_loan__item:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;
      }
    }
  }

  &_how_get {
    padding: 60px 0 15px 0;

    @media screen and (max-width: 769px) {
      padding: 40px 0 20px 0;
    }

    .title {
      text-align: center;
      margin-bottom: 65px;

      @media screen and (max-width: 769px) {
        margin-bottom: 40px;
      }
    }

    &__swiper {
      &.swiper {
        display: none;

        @media screen and (max-width: 1101px) {
          display: block;
          margin-bottom: 60px;
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 100px;
      margin-bottom: 60px;

      @media screen and (max-width: 1101px) {
        display: none;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }

    &__img {
      margin-bottom: 35px;
      height: 100px;

      & > img {
        max-width: 100%;
        height: 100px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      white-space: nowrap;
      text-align: center;
    }

    &__btn_block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      & > .btn {
        @media screen and (max-width: 769px) {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }

  &_how_to_repay {
    padding: 130px 0 140px 0;
    background-image: url("assets/payday_loan_how_get_bachround.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    .title {
      margin-bottom: 85px;
    }

    .car_loan_how_get_loan__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__swiper {
      &.swiper {
        display: none;

        @media screen and (max-width: 1101px) {
          display: block;
        }
      }
    }

    @media screen and (max-width: 1101px) {
      .car_loan_how_get_loan__list {
        display: none;
      }
    }

    @media screen and (max-width: 769px) {
      background-image: url("assets/payday_loan_how_get_bachround_320.png");
      background-color: #fceae2;
      padding: 110px 0;
    }
  }
}

/* business_loan */
.business_loan {
  .footer {
    & > .footer_background {
      background-color: #fcf0eb;
      background-image: url("assets/footer_background.png");
    }

    .footer__top {
      @media screen and (max-width: 601px) {
        padding-top: 400px;
      }
    }
  }

  &__wrapper {
    padding: 75px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
      padding: 25px 0 15px 0;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      @media screen and (max-width: 701px) {
        grid-template-rows: 1fr;
      }

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;

        @media screen and (max-width: 701px) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 3;
          grid-row-end: 3;
        }
      }
    }
  }

  &_from_us {
    padding: 150px 0 75px 0;
    margin-top: 0;
    background-color: #ffffff;
    background-image: url("assets/business_loan_from_us_backround.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;

    @media screen and (max-width: 769px) {
      padding: 50px 0 70px 0;
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/business_loan_from_us_backround_320.png");
    }

    .get_loan__list {
      display: grid;

      @media screen and (max-width: 769px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 479px) {
        grid-template-columns: 1fr;
      }
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;

      @media screen and (max-width: 769px) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }

      @media screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 4;
        grid-row-end: 4;
      }
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;

      @media screen and (max-width: 769px) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }

      @media screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;
      }
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/business_loan_repay_loan_backround.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 501px) {
      background-image: url("assets/business_loan_repay_loan_backround_320.png");
      padding: 95px 0;
    }

    &__img_block {
      height: 100px;
    }

    &__swiper {
      &.swiper {
        display: none;

        @media screen and (max-width: 1101px) {
          display: block;

          .get_loan__item_text {
            white-space: normal;
          }
        }
      }
    }

    .get_loan__list {
      margin-bottom: 0;
    }
  }
}

/* insurance_services */

.insurance_services {
  .footer {
    & > .footer_background {
      background-color: #fcf0eb;
      background-image: url("assets/footer_background.png");
    }

    .footer__top {
      @media screen and (max-width: 601px) {
        padding-top: 400px;
      }
    }
  }

  &__wrapper {
    padding: 75px 0 105px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
      padding: 25px 0 15px 0;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      @media screen and (max-width: 701px) {
        grid-template-rows: 1fr;
      }

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;

        @media screen and (max-width: 701px) {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 3;
          grid-row-end: 3;
        }
      }
    }
  }

  &__loan_from_us {
    background-image: url("assets/insurance_services_get_loan_from_us_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;
    margin-top: 0;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/insurance_services_get_loan_from_us_background.png");
    }

    @media screen and (max-width: 769px) {
      padding: 50px 0 70px 0;
    }

    .get_loan__list {
      display: grid;

      @media screen and (max-width: 769px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 479px) {
        grid-template-columns: 1fr;
      }
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;

      @media screen and (max-width: 769px) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }

      @media screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 4;
        grid-row-end: 4;
      }
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;

      @media screen and (max-width: 769px) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }

      @media screen and (max-width: 479px) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;
      }
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/insurance_services_repay_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/insurance_services_repay_background.png");
    }

    @media screen and (max-width: 501px) {
      background-image: url("assets/insurance_services_repay_background_320.png");
      padding: 95px 0;
    }

    &__img_block {
      height: 100px;
    }

    &__swiper {
      &.swiper {
        display: none;

        @media screen and (max-width: 1101px) {
          display: block;

          .get_loan__item_text {
            white-space: normal;
          }
        }
      }
    }

    .get_loan__list {
      margin-bottom: 0;
    }
  }
}

/* medical_services */

.medical_services {
  &__wrapper {
    padding: 75px 0 105px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;
      }
    }
  }

  &__loan_from_us {
    background-image: url("assets/study_loan_get_loan_from_us_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/medical_services_get_loan_from_us_background.png");
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/study_loan_repay_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/medical_services_repay_background.png");
    }

    .swiper {
      display: none;

      @media screen and (max-width: 1101px) {
        display: block;
      }
    }
  }
}

/* study_loan */
.study_loan {
  &__wrapper {
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 100%;
    }

    @media screen and (max-width: 500px) {
      padding-bottom: 200px;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      @media screen and (max-width: 750px) {
        display: flex;
        flex-direction: column;
      }

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;
      }
    }

    & > .payday_loan__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      & > button {
        padding-left: 45px;
        padding-right: 45px;
        margin-bottom: 60px;

        @media screen and (max-width: 1121px) {
          display: none;
        }
      }
      @media screen and (max-width: 750px) {
        margin-right: 0;
        margin-bottom: 36px;
      }
    }
  }

  &__loan_from_us {
    background-image: url("assets/study_loan_get_loan_from_us_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/study_loan_get_loan_from_us_background.png");
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/study_loan_repay_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/study_loan_repay_background.png");
    }
  }

  &__title {
    color: #107275;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 18px;
  }

  &__desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  &__block_bottom_calculate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 36px;
    padding-bottom: 120px;
    gap: 12px;

    & > button {
      display: none;
    }

    @media screen and (max-width: 1121px) {
      justify-content: space-between;

      & > button {
        display: block;
      }
    }

    @media screen and (max-width: 1050px) {
      flex-direction: column-reverse;
    }
  }

  &_its_credit_need_for {
    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
    }

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
    }

    &__item {
      background-color: #fefefe73;
      padding: 10px 8px;
      display: flex;
      backdrop-filter: blur(12px);
      min-height: 68px;

      & > img {
        margin-right: 10px;
      }

      @media screen and (max-width: 542px) {
        width: 100%;
        min-height: auto;
      }
    }
  }

  &_lending_conditions {
    padding-bottom: 66px;

    .title {
      text-align: center;

      @media screen and (max-width: 412px) {
        text-align: left;
      }
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 6px;
    }

    &__item {
      min-width: 194px;
      min-height: 73px;
      background-color: #fff;
      padding: 10px 16px;
      position: relative;
      font-size: 15px;
      line-height: 18px;

      &_top {
        position: relative;
        z-index: 1;
      }

      &_bottom {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  &_why_profitable_take_loan {
    &__wrapper {
      background-image: url("assets/block_why_need_get_loan.svg");
      //background-color: #F8F0EE;
      background-color: rgb(227, 216, 206);
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 54px;

      .title {
        text-align: center;
        font-size: 45px;
        line-height: 54px;
        padding-top: 100px;
        padding-bottom: 50px;

        & > span {
          color: #107275;
        }

        @media screen and (max-width: 650px) {
          text-align: left;
        }
      }
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 30px;
      flex-wrap: wrap;

      @media screen and (max-width: 1084px) {
        justify-content: center;
      }

      @media screen and (max-width: 500px) {
        justify-content: flex-start;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;

      & > img {
        margin-right: 10px;
      }
    }
  }

  &_to_get_a_loan {
    @media screen and (max-width: 768px) {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 20%,
        rgba(253, 234, 227, 1) 85%,
        rgba(253, 234, 227, 1) 100%
      );
    }
    &__wrapper {
      //background: url("assets/study_loan/student_img.png"), linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(253, 234, 227, 1) 85%, rgba(253, 234, 227, 1) 100%);
      //background: url("assets/block_to_receive_study_loan.png"), linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(253, 234, 227, 1) 85%, rgba(253, 234, 227, 1) 100%);
      //background: url("assets/study_loan/bacground_student_1.png"), linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(253, 234, 227, 1) 85%, rgba(253, 234, 227, 1) 100%);
      background:
        url("assets/study_loan/edited_background.png"),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 20%,
          rgba(253, 234, 227, 1) 85%,
          rgba(253, 234, 227, 1) 100%
        );
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 125px;
      padding-bottom: 205px;
      background-position: 50% 50%;

      //@media screen and (max-width: 834px) {
      //  background-position: 30% top;
      //}
      //@media screen and (max-width: 768px) {
      //  background-image: url("assets/block_to_receive_study_loan_mobile.png");
      //}

      @media screen and (max-width: 500px) {
        padding-bottom: 100px;
        background-position: 35% 50%;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
      flex-wrap: wrap;
    }

    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    }

    &__item {
      max-width: 392px;
      width: 100%;
      background-color: #fefefe;
      padding: 18px 32px;
      box-shadow: 0 0 24px 0 #1072751f;

      &.min_height_68 {
        min-height: 68px;

        @media screen and (max-width: 833px) {
          min-height: auto;
        }
      }
    }
  }

  &_request {
    padding: 50px 0;

    &__wrapper {
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
    }

    &__item {
      background-color: #fefefe;
      padding: 18px 32px;
      box-shadow: 0 0 24px 0 #1072751f;
      border: 1px solid #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      max-width: 280px;

      &_img {
        & > img {
          width: 35px;
          height: 35px;
        }
      }

      @media screen and (max-width: 610px) {
        max-width: 100%;
        width: 100%;
      }
    }

    &__show-more-btn {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }
  }

  h1 {
    margin-top: 38px;
  }

  .footer__top-figure {
    background-color: #f5f3f6;
  }
}

/* personal_loan */
.personal_loan {
  &__wrapper {
    padding: 75px 0 105px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;
      }
    }
  }

  &__loan_from_us {
    background-image: url("assets/personal_loan_get_loan_from_us_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/personal_loan_get_loan_from_us_background.png");
    }

    .get_loan__item:nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .get_loan__item:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 2;
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/personal_loan_repay_background_4k.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fcf2ec;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/personal_loan_repay_background.png");
    }
  }
}

/* refinancing_loan */
.refinancing_loan {
  .footer {
    & > .footer_background {
      background-color: #fcf0eb;
      background-image: url("assets/footer_background.png");
    }

    .footer__top {
      @media screen and (max-width: 601px) {
        padding-top: 400px;
      }
    }
  }

  &__wrapper {
    padding: 75px 0 105px 0;
    display: grid;
    grid-template-columns: minmax(200px, 430px) 1fr;

    @media screen and (max-width: 1121px) {
      grid-template-columns: 1fr;
    }

    & > .car_loan__calculate {
      grid-template-rows: 1fr auto;

      & > .car_loan__calculate_info {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
        color: #0f313f;
        line-height: 18px;
        font-size: 12px;
        margin-top: 20px;
      }

      .car_loan__calculate_left {
        .loan_calculate__slider {
          &:first-child {
            margin-top: 15px;
          }

          margin-top: 30px;
        }
      }
    }
  }

  &__from_us {
    background-image: url("assets/refinancing_loan_from_us_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fff;
    padding: 130px 0 220px 0;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/refinancing_loan_from_us_background.png");
    }

    .get_loan__item {
      justify-content: flex-start;
    }
  }

  &_repay {
    padding: 95px 0 140px 0;
    background-image: url("assets/refinancing_loan_repay_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #fceae2;

    @media screen and (max-width: 1401px) {
      background-image: url("assets/refinancing_loan_repay_background.png");
    }

    &__swiper {
      &.swiper {
        .get_loan__item_text {
          white-space: normal;
        }
      }
    }
  }
}

/* stock */

.stock {
  .top_background {
    position: relative;
    background-color: #f5f3f6;

    .container {
      position: relative;
      z-index: 1;
    }
  }

  &__wrapper {
    margin-top: 60px;

    @media screen and (max-width: 769px) {
      & > .title {
        text-align: center;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 50px;
    margin-top: 60px;

    @media screen and (max-width: 1251px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
      margin: 0 20px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 650px) {
      display: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: flex;
      }
    }

    .btn {
      padding: 15px 35px;
    }
  }

  &__img {
    margin-bottom: 15px;

    & > img {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }

  &__desc {
    margin-bottom: 15px;
    font-size: 18px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0;

    .btn {
      padding: 15px 60px;
    }
  }
}

/* faq_page */
.faq_page {
  .title {
    @media screen and (min-width: 769px) {
      margin-bottom: 60px;
    }
  }

  .top_background {
    position: relative;

    & > .container {
      position: relative;
      z-index: 1;
    }
  }

  .footer_background {
    background-color: #fff;
  }

  &__wrapper {
    margin-top: 60px;
    padding-bottom: 115px;

    @media screen and (max-width: 769px) {
      & > .title {
        text-align: center;
      }
    }
  }
}

/* reviews_page */
.reviews_page {
  .title {
    @media screen and (min-width: 769px) {
      margin-bottom: 60px;
    }
  }

  .top_background {
    position: relative;

    & > .container {
      position: relative;
      z-index: 1;
    }
  }

  .footer_background {
    background-color: #fff;
  }

  &__wrapper {
    margin-top: 60px;
    padding-bottom: 115px;

    @media screen and (max-width: 769px) {
      & > .title {
        text-align: center;
      }
    }

    & > .reviews {
      padding: 20px 0;
    }
  }
}

/* loyalty_program */
.loyalty_program {
  .title {
    @media screen and (min-width: 769px) {
      margin-bottom: 60px;
    }
  }

  .top_background {
    position: relative;

    & > .container {
      position: relative;
      z-index: 1;
    }
  }

  .footer__top-figure {
    background-color: #fff;
  }

  .faq__body {
    background-color: #f5f2ee;
  }

  &__wrapper {
    margin-top: 60px;
    padding-bottom: 115px;

    @media screen and (max-width: 769px) {
      & > .title {
        text-align: center;
      }
    }
  }

  &_wrapper_text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media screen and (max-width: 740px) {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &__col {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.home {
  .footer__top-figure {
    background-color: #fff;
  }
}

/* family loan */
.family_loan {
  &_plans {
    padding: 60px 0 15px 0;

    @media screen and (max-width: 769px) {
      padding: 40px 0 20px 0;
    }

    .title {
      text-align: center;
      margin-bottom: 65px;

      @media screen and (max-width: 769px) {
        margin-bottom: 40px;
      }
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 24px;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 460px;
      gap: 16px;
      border: 1px solid #cdcdcd;
      padding: 10px 16px;
      border-radius: 10px;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    }

    &__title {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }

  &_simple_steps {
    padding: 60px 0;

    @media screen and (max-width: 769px) {
      padding: 40px 0;
    }

    .title {
      text-align: center;
      margin-bottom: 40px;
    }

    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 60px;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 460px;
    }

    &__img {
      width: 100px;
      height: 100px;
      & > img {
        width: 100px;
        height: 100px;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }

  &__title {
    @media screen and (max-width: 1121px) {
      margin-top: 24px;
    }
  }
}

/* Reviews */

.reviews {
  padding: 60px 0;
  background-color: #fcf0eb;

  & > .container > .title {
    text-align: center;
  }

  &__list {
    & > .swiper-wrapper > .swiper-slide {
      display: flex;
      padding: 10px;
    }
  }
  &__item {
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: height 0.5s ease-out;
  }
  &__header {
    font-size: 24px;
    font-weight: bold;
  }
  &__body {
    font-size: 18px;
    line-height: 22px;
    transition: height 0.5s ease-out;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/* Color */
.color {
  &__pink {
    color: #f07c93;
  }
}

div.swiper {
  & > div.swiper-wrapper {
    padding-bottom: 20px;
  }

  & > div.swiper-pagination {
    bottom: 0;

    & > span.swiper-pagination-bullet-active {
      background: #f07c93;
    }
  }
}

ul.ul {
  list-style: disc;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
}

ol.ol {
  list-style: decimal;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }

  & > li {
    margin: 20px 0;
  }
}

ul.blog-ul {
  list-style: disc;
  margin: 18px 0;
  padding-left: 40px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }

  & > li {
    margin: 20px 0;
  }
}

/* Accordion */
.accordion_custom {
  &__header {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
  }
  &__content {
    font-size: 18px;
    line-height: 20px;
    text-align: left;
  }
}

/* MUI */

table.MuiTable-root {
  min-width: 0;
}

div.MuiPaper-root {
  max-width: 100vw;
  @media screen and (max-width: 555px) {
    max-width: 78vw;
  }

  @media screen and (max-width: 450px) {
    max-width: 71vw;
  }

  @media screen and (max-width: 330px) {
    max-width: 66vw;
  }

  &.MuiAccordion-root {
    max-width: 100vw;
  }
}

/* scroll-to-top  */
button.scroll-to-top {
  right: 25px;
  bottom: 90px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.prolongation-guide {
  .footer__top-figure {
    background-color: #f5f3f6;
  }
}
